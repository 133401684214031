// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/mistral/CopyofMistral2.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: mistral2;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\nbody {\n  overflow-y: scroll;\n  position: relative;\n  margin: 0;\n  font-family: mistral2;\n}\n\n::-webkit-scrollbar {\n  width: 8px;\n}\n\n::-webkit-scrollbar-track {\n  background-color: #999;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #bbb;\n  border-radius: 8px;\n  border: 1px solid #666;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #777;\n}\n\n.page--container {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,4CAA4C;AAC9C;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":["@font-face {\n  font-family: mistral2;\n  src: url(./fonts/mistral/CopyofMistral2.ttf);\n}\n\nbody {\n  overflow-y: scroll;\n  position: relative;\n  margin: 0;\n  font-family: mistral2;\n}\n\n::-webkit-scrollbar {\n  width: 8px;\n}\n\n::-webkit-scrollbar-track {\n  background-color: #999;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #bbb;\n  border-radius: 8px;\n  border: 1px solid #666;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #777;\n}\n\n.page--container {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
