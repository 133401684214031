import React from 'react';

import classes from './Footer.module.css';

import tlo from './../img/tło.jpg';

const Footer = () => {
  return (
    <nav>
      <div
        className={classes['footer']}
        style={{ backgroundImage: `url(${tlo})` }}
      >
        <div className={classes['footer--container']}>
          <a href='mailto:Edukownia.chludowo@gmail.com'>
            Skontaktuj się ze mną: Edukownia.chludowo@gmail.com
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Footer;
