// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainPage_main--page__eVDO0 {\n  width: 100%;\n}\n\n.MainPage_main--page--wrapper__p4wwI {\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.MainPage_bg--img__9jRml {\n  width: 100%;\n  border-top: 1px solid #888;\n  border-bottom: 1px solid #888;\n  opacity: 0.9;\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */\n  user-select: none;\n\n  object-fit: cover;\n  height: 300px;\n}\n\n@media (max-width: 768px) {\n  .MainPage_main--page__eVDO0 {\n    z-index: -5;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/MainPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,0BAA0B;EAC1B,6BAA6B;EAC7B,YAAY;EACZ,2BAA2B,EAAE,eAAe;EAC5C,yBAAyB,EAAE,WAAW,EACZ,mBAAmB,EACrB,4BAA4B,EAC7B,2BAA2B;EAClD,iBAAiB;;EAEjB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".main--page {\n  width: 100%;\n}\n\n.main--page--wrapper {\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.bg--img {\n  width: 100%;\n  border-top: 1px solid #888;\n  border-bottom: 1px solid #888;\n  opacity: 0.9;\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Old versions of Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none;\n\n  object-fit: cover;\n  height: 300px;\n}\n\n@media (max-width: 768px) {\n  .main--page {\n    z-index: -5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main--page": "MainPage_main--page__eVDO0",
	"main--page--wrapper": "MainPage_main--page--wrapper__p4wwI",
	"bg--img": "MainPage_bg--img__9jRml"
};
export default ___CSS_LOADER_EXPORT___;
