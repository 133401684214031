// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyMap_marker-content__bV7nv {\n  font-weight: bold;\n  border-left: 5px solid #20aaff;\n  padding-left: 10px;\n  margin-left: -10px;\n  line-height: 1.8;\n}\n", "",{"version":3,"sources":["webpack://./src/components/MyMap.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,8BAA8B;EAC9B,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".marker-content {\n  font-weight: bold;\n  border-left: 5px solid #20aaff;\n  padding-left: 10px;\n  margin-left: -10px;\n  line-height: 1.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marker-content": "MyMap_marker-content__bV7nv"
};
export default ___CSS_LOADER_EXPORT___;
