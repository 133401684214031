import React from 'react';
import { Link } from 'react-router-dom';

import classes from './NavSmallBtns.module.css';

const NavSmallBtns = (props) => {
  return (
    <div className={classes['nav--small--btns--container']}>
      <div
        className={
          props.showSmallNav
            ? classes['nav--small--btns']
            : classes['nav--small--btns--hidden']
        }
      >
        <Link to='/'>
          <div className={classes['nav--small--btn']}>STRONA GŁÓWNA</div>
        </Link>
        <Link to='/offer'>
          <div className={classes['nav--small--btn']}>OFERTA</div>
        </Link>
        <Link to='/pricing'>
          <div className={classes['nav--small--btn']}>CENNIK</div>
        </Link>
        <Link to='/contact'>
          <div className={classes['nav--small--btn']}>KONTAKT</div>
        </Link>
      </div>
    </div>
  );
};

export default NavSmallBtns;
