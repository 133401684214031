import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import classes from './App.css';

import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';
import MainPage from './pages/MainPage';
import OfferPage from './pages/OfferPage';
import PricingPage from './pages/PricingPage';
import ContactPage from './pages/ContactPage';

function App() {
  const [showSmallNav, setShowSmallNav] = useState(false);

  const closeSmallNav = () => {
    setShowSmallNav(false);
  };

  return (
    <div className={classes['page--container']}>
      <Router>
        <Navbar showSmallNav={showSmallNav} setShowSmallNav={setShowSmallNav} />

        <Routes>
          <Route
            element={<MainPage closeSmallNav={closeSmallNav} />}
            path='/'
          />
          <Route
            element={<OfferPage closeSmallNav={closeSmallNav} />}
            path='/offer'
          />
          <Route
            element={<PricingPage closeSmallNav={closeSmallNav} />}
            path='/pricing'
          />
          <Route
            element={<ContactPage closeSmallNav={closeSmallNav} />}
            path='/contact'
          />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
