import React from "react";

import classes from "./NavToggleSmall.module.css";

const NavToggleSmall = (props) => {
  return (
    <div
      className={classes["nav--toggle--small"]}
      onClick={props.toggleSmallNav}
    >
      <div className={classes["bar1"]}></div>
      <div className={classes["bar2"]}></div>
      <div className={classes["bar3"]}></div>
    </div>
  );
};

export default NavToggleSmall;
