import React from 'react';

import classes from './BgImage.module.css';
import image from './../img/123.jpg';

const BgImage = () => {
  return (
    <div
      className={classes['bg--img']}
      style={{
        backgroundImage: `url(${image})`,
      }}
    ></div>
  );
};

export default BgImage;
