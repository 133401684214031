import React from 'react';

import classes from './About.module.css';
import background1 from './../img/wjazd.jpg';
import background2 from './../img/masa.jpg';
import background3 from './../img/MI.jpg';

const About = () => {
  return (
    <React.Fragment>
      <div className={classes['about']}>
        <div
          className={classes['about--title']}
          style={{
            backgroundImage: `url(${background1})`,
          }}
        >
          O NAS
        </div>
        <div className={classes['about--content']}>
          Edukownia
          <br />
          to miejsce stworzone przez specjalistki
          <br />
          w zakresie
          <br />
          wspierania rozwoju
          <br />
          dzieci i młodzieży.
        </div>
        <div
          className={classes['about--title']}
          style={{
            backgroundImage: `url(${background2})`,
          }}
        >
          ZAJĘCIA
        </div>
        <div className={classes['about--content']}>
          Metody
          <br />
          naszej pracy
          <br />
          dostosowujemy
          <br />
          do potrzeb
          <br />i zainteresowań dziecka.
        </div>
        <div
          className={classes['about--title']}
          style={{
            backgroundImage: `url(${background3})`,
          }}
        >
          NAUKA
        </div>
        <div className={classes['about--content']}>
          Znajdziesz u nas
          <br />
          pomoc
          <br />
          pedagogiczną,
          <br />
          logopedyczną
          <br />i psychologiczną.
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
