import React from 'react';

import classes from './ContactEl.module.css';

const ContactEl = () => {
  return (
    <div className={classes['contact--wrapper']}>
      <div className={classes['contact--address']}>
        <div className={classes['contact--header']}>Adres</div>
        Poznańska 22
        <br /> 62-001 Chludowo
      </div>
      <div className={classes['contact--email']}>
        <div className={classes['contact--header']}>Email</div>
        Edukownia.chludowo@gmail.com
      </div>
      <div className={classes['contact--phone']}>
        <div className={classes['contact--header']}>Tel.</div>
        609-077-788
      </div>
    </div>
  );
};

export default ContactEl;
