// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a {\n  text-decoration: none;\n}\n\n.Navbar_navbar__Ync-U {\n  width: 100%;\n  box-shadow: 0 10px 5px -5px rgb(200, 200, 200);\n  position: fixed;\n  top: 0;\n  z-index: 99;\n}\n\n.Navbar_nav--container__nSSPj {\n  display: flex;\n  height: 60px;\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n  justify-content: space-between;\n  vertical-align: middle;\n}\n\n.Navbar_nav--logo__V1w2V {\n  height: 56px;\n  margin-left: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/navbar/Navbar.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,8CAA8C;EAC9C,eAAe;EACf,MAAM;EACN,WAAW;AACb;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":["a {\n  text-decoration: none;\n}\n\n.navbar {\n  width: 100%;\n  box-shadow: 0 10px 5px -5px rgb(200, 200, 200);\n  position: fixed;\n  top: 0;\n  z-index: 99;\n}\n\n.nav--container {\n  display: flex;\n  height: 60px;\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n  justify-content: space-between;\n  vertical-align: middle;\n}\n\n.nav--logo {\n  height: 56px;\n  margin-left: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "Navbar_navbar__Ync-U",
	"nav--container": "Navbar_nav--container__nSSPj",
	"nav--logo": "Navbar_nav--logo__V1w2V"
};
export default ___CSS_LOADER_EXPORT___;
