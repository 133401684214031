import React from 'react';

import classes from './ContactPage.module.css';

import PageHeader from '../components/PageHeader';
import Quote from '../components/Quote';
import BgImage from '../components/BgImage';
import PricingEl from '../components/PricingEl';

const PricingPage = (props) => {
  return (
    <div className={classes['main--page']} onClick={props.closeSmallNav}>
      <div className={classes['main--page--wrapper']}>
        <BgImage />

        <PageHeader headerText={'CENNIK'} />

        <PricingEl />

        <Quote
          quote={'Prościej jest rządzić narodem, niż wychować czwórkę dzieci.'}
          author={'Winston Churchill'}
        />
      </div>
    </div>
  );
};

export default PricingPage;
