import React from 'react';

import classes from './OfferPage.module.css';

import PageHeader from '../components/PageHeader';
import Quote from '../components/Quote';
import BgImage from '../components/BgImage';
import Offer from '../components/Offer';

const OfferPage = (props) => {
  return (
    <div className={classes['main--page']} onClick={props.closeSmallNav}>
      <div className={classes['main--page--wrapper']}>
        <BgImage />

        <PageHeader headerText={'OFERTA'} />
        <Offer />
        <Quote
          quote={
            'W swoich dzieciach widzimy siebie, lecz takich, którzy mogą dokonać lepszych wyborów niż my sami.'
          }
          author={'Cassandra Clare'}
        />
      </div>
    </div>
  );
};

export default OfferPage;
