import React from 'react';

import classes from './MainPage.module.css';

import PageHeader from '../components/PageHeader';
import About from '../components/About';
import Quote from '../components/Quote';
import BgImage from '../components/BgImage';

const MainPage = (props) => {
  return (
    <div className={classes['main--page']} onClick={props.closeSmallNav}>
      <div className={classes['main--page--wrapper']}>
        <BgImage />

        <PageHeader
          headerText={
            'GABINET TERAPII PSYCHOLOGICZNO - PEDAGOGICZNO - LOGOPEDYCZNEJ'
          }
        />
        <About />
        <Quote
          quote={
            'Wszyscy dorośli byli kiedyś dziećmi, ale niewielu z nich pamięta o tym.'
          }
          author={'Antoine De Saint-Exupery'}
        />
      </div>
    </div>
  );
};

export default MainPage;
