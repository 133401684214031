import React from 'react';
import { Link } from 'react-router-dom';

import classes from './NavBtns.module.css';

const NavBtns = () => {
  return (
    <div className={classes['nav--btns']}>
      <Link to='/'>
        <button className={classes['nav--btn']}>STRONA GŁÓWNA</button>
      </Link>
      <Link to='/offer'>
        <button className={classes['nav--btn']}>OFERTA</button>
      </Link>
      <Link to='/pricing'>
        <button className={classes['nav--btn']}>CENNIK</button>
      </Link>
      <Link to='/contact'>
        <button className={classes['nav--btn']}>KONTAKT</button>
      </Link>
    </div>
  );
};

export default NavBtns;
