import React from 'react';

import classes from './PricingEl.module.css';

const PricingEl = () => {
  return (
    <div className={classes['pricing--wrapper']}>
      <div className={classes['pricing--name']}>Nauka czytania</div>
      <div>50 minut</div>
      <div>80 zł</div>
      <div className={classes['pricing--name']}>Zajęcia wyrównawcze</div>
      <div>50 minut</div>
      <div>80 zł</div>
      <div className={classes['pricing--name']}>Terapia ręki</div>
      <div>50 minut</div>
      <div>90 zł</div>
      <div className={classes['pricing--name']}>
        Zajęcia terapeutyczne <br />
        (rewalidacja, korekcyjno-kompensacyjne)
      </div>
      <div>50 minut</div>
      <div>90 zł</div>
      <div className={classes['pricing--name']}>Psycholog</div>
      <div>60 minut</div>
      <div>160 zł</div>
      <div className={classes['pricing--name']}>Logopeda</div>
      <div>30-45 minut</div>
      <div>80-100 zł</div>
    </div>
  );
};

export default PricingEl;
