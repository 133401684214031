import React from 'react';

import 'leaflet/dist/leaflet.css';
import classes from './MyMap.module.css';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import iconMarker from 'leaflet/dist/images/marker-icon.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
});

const MyMap = () => {
  return (
    <MapContainer
      center={[52.552308, 16.850046]}
      zoom={13}
      scrollWheelZoom={false}
      style={{ height: '500px', borderRadius: 10, zIndex: 1 }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <Marker position={[52.55383729175169, 16.843734341076903]}>
        <Popup>
          <div className={classes['marker-content']}>
            Poznańska 22 <br /> 62-001 Chludowo
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MyMap;
