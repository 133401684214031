// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BgImage_bg--img__qdvUG {\n  width: 100%;\n  border-top: 1px solid #888;\n  border-bottom: 1px solid #888;\n\n  background-attachment: fixed;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  opacity: 0.9;\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */\n  user-select: none;\n\n  object-fit: cover;\n  height: 300px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/BgImage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,0BAA0B;EAC1B,6BAA6B;;EAE7B,4BAA4B;EAC5B,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;;EAEtB,YAAY;EACZ,2BAA2B,EAAE,eAAe;EAC5C,yBAAyB,EAAE,WAAW,EACZ,mBAAmB,EACrB,4BAA4B,EAC7B,2BAA2B;EAClD,iBAAiB;;EAEjB,iBAAiB;EACjB,aAAa;AACf","sourcesContent":[".bg--img {\n  width: 100%;\n  border-top: 1px solid #888;\n  border-bottom: 1px solid #888;\n\n  background-attachment: fixed;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  opacity: 0.9;\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Old versions of Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none;\n\n  object-fit: cover;\n  height: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg--img": "BgImage_bg--img__qdvUG"
};
export default ___CSS_LOADER_EXPORT___;
