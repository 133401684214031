import React from 'react';

import classes from './ContactPage.module.css';

import PageHeader from '../components/PageHeader';
import Quote from '../components/Quote';
import BgImage from '../components/BgImage';
import MyMap from '../components/MyMap';
import ContactEl from '../components/ContactEl';

const ContactPage = (props) => {
  return (
    <div className={classes['main--page']} onClick={props.closeSmallNav}>
      <div className={classes['main--page--wrapper']}>
        <BgImage />

        <PageHeader headerText={'KONTAKT'} />

        <MyMap />

        <ContactEl />

        <Quote
          quote={
            'Nie oczekuj, że Twoje dziecko będzie takie, jakim Ty chcesz, żeby było. Pomóż mu stać się sobą, a nie Tobą.'
          }
          author={'Janusz Korczak'}
        />
      </div>
    </div>
  );
};

export default ContactPage;
