import React from 'react';

import classes from './Offer.module.css';

const Offer = () => {
  return (
    <React.Fragment>
      <div className={classes['offer']}>
        <div>
          <div className={classes['offer--title']}>PEDAGOG</div>
          <div className={classes['offer--content']}>
            <div className={classes['offer--content--header']}>
              Zajęcia indywidualne:
            </div>
            <ul>
              <li>nauka czytania</li>
              <li>
                zajęcia terapeutyczne (korekcyjno - kompensacyjne,
                rewalidacyjne)
              </li>
              <li>
                zajęcia wyrównawcze, utrwalanie materiału przerabianego w szkole
              </li>
              <li>terapia ręki</li>
            </ul>
            <div className={classes['offer--content--header']}>
              Zajęcia grupowe:
            </div>
            <ul>
              <li>
                przygotowanie do konkursów szkolnych dla klas I-III (Kangur,
                Alfik, …)
              </li>
              <li>Emocje z Edusiem</li>
            </ul>
          </div>
        </div>

        <div>
          <div className={classes['offer--title']}>LOGOPEDA</div>
          <div className={classes['offer--content']}>
            <div className={classes['offer--content--header']}>
              Diagnoza, konsultacje, terapia:
            </div>
            <ul>
              <li>wsparcie umiejętności mówienia i rozumienia</li>
              <li>wyrównywanie opóźnień rozwoju mowy</li>
              <li>
                korygowanie wad wymowy (seplenienie, reranie, kapacyzm, mowa
                bezdźwięczne i inne)
              </li>
              <li>
                wsparcie przywracania mowy i rozumienia po jej utracie (afazja)
              </li>
              <li>terapia zaburzeń tempa mowy (jąkanie)</li>
              <li>doskonalenie słuchu fonematycznego</li>
            </ul>
          </div>
        </div>

        <div>
          <div className={classes['offer--title']}>PSYCHOLOG</div>
          <div className={classes['offer--content']}>
            <div className={classes['offer--content--header']}>
              Wsparcie dzieci i opiekunów:
            </div>
            <ul>
              <li>trudności emocjonalne</li>
              <li>zaburzenia rozwoju</li>
              <li>zaburzenia osobowości</li>
              <li>strata</li>
            </ul>
          </div>
        </div>

        {/* <div>
          <div className={classes['offer--title']}>
            ZAJĘCIA KOREKCYJNO-PEDAGOGICZNE
          </div>
          <div className={classes['offer--content']}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            sollicitudin nibh eros, vel auctor metus scelerisque eu. Nam aliquam
            mollis sollicitudin. Sed nec nunc nisl. Phasellus ultricies ultrices
            sapien nec volutpat. Sed id laoreet nunc, et iaculis ligula.
            Curabitur dignissim consectetur lacus sit amet porttitor. In hac
            habitasse platea dictumst. Praesent quis tortor massa. Sed dictum
            felis non leo vehicula laoreet. Donec gravida ipsum nec libero
            feugiat, id fermentum sem lobortis. Nulla aliquet pulvinar lacinia.
            Curabitur auctor malesuada elit, non rutrum nibh fringilla et.
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Offer;
