import React from 'react';

import classes from './Quote.module.css';

const Quote = (props) => {
  return (
    <div className={classes['quote--wrapper']}>
      <div className={classes['quote']}>{props.quote}</div>
      <div className={classes['author']}>{props.author}</div>
    </div>
  );
};

export default Quote;
