import React from 'react';

import classes from './PageHeader.module.css';

const PageHeader = (props) => {
  return (
    <React.Fragment>
      <div className={classes['header-top']}>
        <div className={classes['rectangle-top']}></div>
      </div>
      <div className={classes['header']}>
        <div className={classes['header--text']}>{props.headerText}</div>
      </div>
      <div className={classes['header-bottom']}>
        <div className={classes['rectangle-bottom']}></div>
      </div>
    </React.Fragment>
  );
};

export default PageHeader;
