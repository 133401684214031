// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavToggleSmall_nav--toggle--small__-vsWv {\n  display: none;\n  cursor: pointer;\n  margin-top: 8px;\n  margin-right: 30px;\n  height: 35px;\n  padding: 5px;\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 5px;\n  transition: 0s background-color;\n}\n\n.NavToggleSmall_nav--toggle--small__-vsWv:hover {\n  transition-delay: 0.1s;\n}\n\n.NavToggleSmall_bar1__jxOW8,\n.NavToggleSmall_bar2__tYxKC,\n.NavToggleSmall_bar3__tr1LV {\n  width: 30px;\n  height: 3px;\n  background-color: #333;\n  margin: 6px 0;\n}\n\n@media (max-width: 900px) {\n  .NavToggleSmall_nav--toggle--small__-vsWv {\n    display: inline-block;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/navbar/NavToggleSmall.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;;EAGE,WAAW;EACX,WAAW;EACX,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".nav--toggle--small {\n  display: none;\n  cursor: pointer;\n  margin-top: 8px;\n  margin-right: 30px;\n  height: 35px;\n  padding: 5px;\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 5px;\n  transition: 0s background-color;\n}\n\n.nav--toggle--small:hover {\n  transition-delay: 0.1s;\n}\n\n.bar1,\n.bar2,\n.bar3 {\n  width: 30px;\n  height: 3px;\n  background-color: #333;\n  margin: 6px 0;\n}\n\n@media (max-width: 900px) {\n  .nav--toggle--small {\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav--toggle--small": "NavToggleSmall_nav--toggle--small__-vsWv",
	"bar1": "NavToggleSmall_bar1__jxOW8",
	"bar2": "NavToggleSmall_bar2__tYxKC",
	"bar3": "NavToggleSmall_bar3__tr1LV"
};
export default ___CSS_LOADER_EXPORT___;
