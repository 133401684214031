import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Navbar.module.css';
import logo from './../img/logo.png';
import tlo from './../img/tło.jpg';

import NavBtns from './NavBtns';
import NavToggleSmall from './NavToggleSmall';
import NavSmallBtns from './NavSmallBtns';

const Navbar = (props) => {
  const toggleSmallNav = () => {
    props.setShowSmallNav(!props.showSmallNav);
  };

  return (
    <nav>
      <div
        className={classes['navbar']}
        style={{ backgroundImage: `url(${tlo})` }}
      >
        <div className={classes['nav--container']}>
          <Link to='/'>
            <img src={logo} className={classes['nav--logo']} alt='Logo' />
          </Link>
          <NavBtns />
          <NavToggleSmall toggleSmallNav={toggleSmallNav} />
        </div>
      </div>
      <NavSmallBtns showSmallNav={props.showSmallNav} />
    </nav>
  );
};

export default Navbar;
