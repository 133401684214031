// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactPage_main--page__QtuOk {\n  width: 100%;\n}\n\n.ContactPage_main--page--wrapper__fPZPn {\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n@media (max-width: 768px) {\n  .ContactPage_main--page__QtuOk {\n    z-index: -5;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ContactPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".main--page {\n  width: 100%;\n}\n\n.main--page--wrapper {\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n@media (max-width: 768px) {\n  .main--page {\n    z-index: -5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main--page": "ContactPage_main--page__QtuOk",
	"main--page--wrapper": "ContactPage_main--page--wrapper__fPZPn"
};
export default ___CSS_LOADER_EXPORT___;
