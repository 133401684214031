// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a {\n  text-decoration: none;\n}\n\n.Footer_footer__MjS6Z {\n  width: 100%;\n  background-color: rgba(140, 155, 155, 1);\n  padding-bottom: 35px;\n  padding-top: 35px;\n  box-shadow: 0px -5px 2px -2px rgb(220, 220, 220);\n}\n\n.Footer_footer--container__ybJoh {\n  max-width: 1200px;\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n  font-size: x-large;\n  font-weight: bold;\n  color: #eee;\n}\n\n.Footer_footer--container__ybJoh > a {\n  color: #eee;\n}\n", "",{"version":3,"sources":["webpack://./src/footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,wCAAwC;EACxC,oBAAoB;EACpB,iBAAiB;EACjB,gDAAgD;AAClD;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":["a {\n  text-decoration: none;\n}\n\n.footer {\n  width: 100%;\n  background-color: rgba(140, 155, 155, 1);\n  padding-bottom: 35px;\n  padding-top: 35px;\n  box-shadow: 0px -5px 2px -2px rgb(220, 220, 220);\n}\n\n.footer--container {\n  max-width: 1200px;\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n  font-size: x-large;\n  font-weight: bold;\n  color: #eee;\n}\n\n.footer--container > a {\n  color: #eee;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer_footer__MjS6Z",
	"footer--container": "Footer_footer--container__ybJoh"
};
export default ___CSS_LOADER_EXPORT___;
