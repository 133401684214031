// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferPage_main--page__-c29\\+ {\n  width: 100%;\n}\n\n.OfferPage_main--page--wrapper__TcDLs {\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n@media (max-width: 768px) {\n  .OfferPage_main--page__-c29\\+ {\n    z-index: -5;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OfferPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".main--page {\n  width: 100%;\n}\n\n.main--page--wrapper {\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n@media (max-width: 768px) {\n  .main--page {\n    z-index: -5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main--page": "OfferPage_main--page__-c29+",
	"main--page--wrapper": "OfferPage_main--page--wrapper__TcDLs"
};
export default ___CSS_LOADER_EXPORT___;
